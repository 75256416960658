'use client';

import React, { PropsWithChildren } from 'react';
import { SWRConfig } from 'swr';
import { fetcher } from '@/lib/SWR/fetchers/fetcher';

export default function SWRProvider(props: PropsWithChildren) {
  return (
    <SWRConfig
      value={{
        errorRetryCount: 3,
        errorRetryInterval: 15000,
        fetcher,
        keepPreviousData: true,
      }}
    >
      {props.children}
    </SWRConfig>
  );
}
