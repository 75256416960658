import {
  useConsent as useUserCentricConsent,
  UserCentricsServiceId,
  ConsentState,
} from '@grimme/gdap';

export const useIsConsentGiven = (
  userCentricsServiceId: UserCentricsServiceId,
) => {
  const consent = useUserCentricConsent(userCentricsServiceId);

  switch (consent) {
    case ConsentState.GRANTED:
      return true;
    case ConsentState.REJECTED:
      return false;
    case ConsentState.LOADING:
    default:
      return undefined;
  }
};
