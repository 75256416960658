'use client';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import { library } from '@fortawesome/fontawesome-svg-core';

class FontAwesomeClientService {
  includeIcons = async (iconDefinitions: IconDefinition[] = []) => {
    library.add(iconDefinitions);
  };
}

export const fontAwesomeClientService = new FontAwesomeClientService();
