'use client';

import React, { PropsWithChildren } from 'react';
import {
  ComponentsConfigProvider,
  GrimmeComponentsProvider as OriginalGrimmeComponentsProvider,
  LocalizationProvider,
} from '@grimme/components';
import { useTranslations } from '@/lib/i18n/use-translations';

export default function GrimmeComponentsProvider(
  props: PropsWithChildren<{
    locale: string;
  }>,
) {
  const t = useTranslations();

  return (
    <ComponentsConfigProvider
      t={t as (key: string, defaultValue?: string) => string}
      language={props.locale}
    >
      <OriginalGrimmeComponentsProvider>
        <LocalizationProvider>{props.children}</LocalizationProvider>
      </OriginalGrimmeComponentsProvider>
    </ComponentsConfigProvider>
  );
}
