import(/* webpackMode: "eager", webpackExports: ["AppProviders"] */ "/app/app/[locale]/_providers/app-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@grimme/buttery/dist/lib/index.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@grimme/ui-components/dist/lib/index.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/global.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
