import { withNotificationsReducer } from '@grimme/components';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AUTH_FEATURE_KEY, authReducer } from './auth.slice';
import {
  MACHINE_CONFIGURATIONS_FEATURE_KEY,
  machineConfigurationsReducer,
} from './machine-configurations.slice';
import {
  MACHINES_FEATURE_KEY as MACHINES_FEATURE_KEY_V2,
  machinesReducer as machinesReducerV2,
} from './machines-v2.slice';
import { MACHINES_FEATURE_KEY, machinesReducer } from './machines.slice';
import { TELEMETRY_FEATURE_KEY, telemetryReducer } from './telemetry.slice';

const reducers = withNotificationsReducer({
  [AUTH_FEATURE_KEY]: authReducer,
  [MACHINES_FEATURE_KEY]: machinesReducer,
  [MACHINES_FEATURE_KEY_V2]: machinesReducerV2,
  [MACHINE_CONFIGURATIONS_FEATURE_KEY]: machineConfigurationsReducer,
  [TELEMETRY_FEATURE_KEY]: telemetryReducer,
});

const reducer = combineReducers(reducers);

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',

  // Optional Redux store enhancers
  enhancers: (getDefaultEnhancers) => getDefaultEnhancers(),

  reducer,
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
