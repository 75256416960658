import {
  AnalyticsProvider,
  ConsentState,
  useConsent,
  UserCentricsServiceId,
} from '@grimme/gdap';
import { useSession } from '@grimme/next-grimme-auth';
import { PropsWithChildren } from 'react';
import { environment } from '~/environments';

export function GdapProvider({ children }: PropsWithChildren) {
  const { appVersion, commitHash, insightsKey, projectName } = environment;

  if (!commitHash || !insightsKey || !projectName || !appVersion) {
    const notSet: string[] = [];
    const envs = { appVersion, commitHash, insightsKey, projectName };
    for (const name in envs) {
      if (!envs[name as keyof typeof envs]) {
        notSet.push(name);
      }
    }
    throw new Error(
      `The following environment variables have not been set: ${notSet.join(
        ', ',
      )}`,
    );
  }

  const { data } = useSession();

  const isAiConsentGiven = useConsent(
    UserCentricsServiceId.AZURE_APPLICATION_INSIGHTS,
  );

  return (
    <AnalyticsProvider
      defaultEnvelopeData={{
        commit: commitHash,
        project: projectName,
        version: appVersion,
      }}
      instrumentationKey={insightsKey}
      isConsentGiven={isAiConsentGiven === ConsentState.GRANTED}
      username={data?.user?.email}
    >
      {children}
    </AnalyticsProvider>
  );
}
