'use client';

import { useSession } from '@grimme/next-grimme-auth';
import { useEffect } from 'react';
import { useAppDispatch } from '~/redux';
import { fetchMe } from '~/redux/auth.slice';

export function AuthProvider({ children }: { children: JSX.Element }) {
  const dispatch = useAppDispatch();
  const { data: sessionData, status } = useSession();

  useEffect(() => {
    if (status !== 'authenticated' || !sessionData) {
      return;
    }

    dispatch(fetchMe({ accessToken: sessionData.accessToken }));

    // session in dependancy array causes page to rerender
    // too often without need;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, status]);

  return children;
}
