'use client';

import React, { PropsWithChildren } from 'react';
import theme from '@grimme/theme';
import { CssBaseline, ThemeProvider, Typography } from '@mui/material';

export default function MUIProvider(props: PropsWithChildren) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Typography component="div">{props.children}</Typography>
    </ThemeProvider>
  );
}
