'use client';

import React, { PropsWithChildren } from 'react';

const ShowContactValueContext = React.createContext<boolean>(true);

const ShowContactSetterContext = React.createContext<
  React.Dispatch<React.SetStateAction<boolean>>
>(
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
);

export const useShowContactValue = () => {
  return React.useContext(ShowContactValueContext);
};

export const useShowContactSetter = () => {
  return React.useContext(ShowContactSetterContext);
};

export const ShowContactProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [showContact, setShowContact] = React.useState(true);
  return (
    <ShowContactSetterContext.Provider value={setShowContact}>
      <ShowContactValueContext.Provider value={showContact}>
        {children}
      </ShowContactValueContext.Provider>
    </ShowContactSetterContext.Provider>
  );
};
