'use client';

import { TooltipProvider } from '@grimme/ui-components/tooltip';
import React, { FC, PropsWithChildren } from 'react';
import { AbstractIntlMessages, NextIntlClientProvider } from 'next-intl';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { ShowContactProvider } from '~/utils/useShowContact';
import { fontAwesomeClientService } from '@/lib/font-awesome-icons/font-awesome.client-service';
import { GdapProvider } from './providers/gdap-provider';
import { AuthProvider } from './providers/auth-provider';
import { SentryProvider } from './providers/sentry-provider';
import { ReduxProvider } from './providers/redux-provider';
import SWRProvider from './providers/swr-provider';
import MUIProvider from './providers/mui-provider';
import GrimmeComponentsProvider from './providers/grimme-components-provider';

export const AppProviders: FC<
  PropsWithChildren<{
    customIcons: IconDefinition[];
    locale: string;
    translations: AbstractIntlMessages;
  }>
> = ({ children, customIcons, locale, translations }) => {
  fontAwesomeClientService.includeIcons(customIcons);

  return (
    <React.StrictMode>
      <GdapProvider>
        <SentryProvider>
          <NextIntlClientProvider
            locale={locale}
            messages={translations}
            onError={() => {}}
          >
            <ReduxProvider>
              <SWRProvider>
                <TooltipProvider>
                  <MUIProvider>
                    <GrimmeComponentsProvider locale={locale}>
                      <AuthProvider>
                        <ShowContactProvider>{children}</ShowContactProvider>
                      </AuthProvider>
                    </GrimmeComponentsProvider>
                  </MUIProvider>
                </TooltipProvider>
              </SWRProvider>
            </ReduxProvider>
          </NextIntlClientProvider>
        </SentryProvider>
      </GdapProvider>
    </React.StrictMode>
  );
};
